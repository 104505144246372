<template>

    <div class="dark:bg-black bg-white">
        <div
            class="background dark:bg-gray-900  bg-gray-400 mt-16 flex flex-col h-screen w-full justify-center items-center p-50"
        >
            <h1 class="text-4xl mb-20 text-center text-blue-200">{{ status }}</h1>

            <router-link class=" flex text-center text-white text-xl hover:text-green-300" tag="a" to="/">На главную
                <img
                    class="ml-5" src="@/assets/img/icons/rightarr.svg" alt="Белтаможсервис"></router-link>
        </div>
    </div>

</template>

<script>
import axios from "axios";

export default {
    meta: {
        title: 'Подтверждение подписки'
    },
    name: "SubscribeConfirm",
    data: () => ({
        status: 'Подождите...'
    }),
    mounted() {
        this.confirm()
    },
    methods: {
        confirm() {

            let id = this.$route.query.ID
            let code = this.$route.query.CONFIRM_CODE

            axios.post('https://declarant.by/rest/subscribe/confirm', {id, code})
                .then(res => {
                    this.status = res.data.data
                    this.$notify({
                        title: 'Успешно',
                        text: res.data.data,
                        type: 'success'
                    })
                }).catch(err => {
                this.$notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })

        },
    }
}
</script>

<style scoped>
.background {
    clip-path: polygon(0 20vh, 100% 0, 100% 80vh, 0 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;


}
</style>